@import url('https://fonts.googleapis.com/css?family=Alegreya+Sans+SC:300,400&display=swap');
@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400&display=swap');
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400&display=swap');

::-webkit-scrollbar{width: 7px;}
::-webkit-scrollbar-track{background-color: darken(white, 40%)}
::-webkit-scrollbar-thumb{background-color: darken(white, 70%);border-radius: 5px;}
::-webkit-scrollbar:horizontal{height: 7px;}
::-webkit-scrollbar-track:horizontal{background-color: transparent;}
::-webkit-scrollbar-thumb:horizontal{background-color: darken(white, 70%);border-radius: 5px;}
*{
  margin: 0;
  padding: 0;
  font-family: Montserrat;
  line-height: 1.5rem;
}
html{scroll-behavior: smooth;}
h1 {
  font-family: 'Alegreya Sans SC', sans-serif;
  font-size: 1.8rem;
  font-weight: 300;
  letter-spacing: 1px;
}
li{list-style: none;}
a{
  text-decoration: none;
  color: inherit;
}