@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400&display=swap');
@mixin flex {
  display: flex;
  justify-content: center;
  align-items: center;
}
.App {
  position: fixed;
  left: 0; top: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(17, 17, 17);
}

.drawer{
  *{padding: 10px 0px; text-decoration: none; color: inherit; font-family: Montserrat;}
  text-align: center;
  h1:not(:first-child){border-top: 1px solid white;}
  h1{
    border-bottom: 1px solid white;
    padding: 5vh;
    font-size: 1.8rem;
    font-weight: 300;
    letter-spacing: 1px;
  }
  p{
    cursor: pointer;
    margin: 1px 0;
    padding: 15px 0;
    width: 100%;
    transition: all .3s;
    &:hover{
      background-color: rgb(31, 31, 31);
      transition: all .3s;
    }
  }
}

.easy{      box-shadow: 0px 0px 20px 0px rgba(255, 255, 255, 0.2);}
.hard{      box-shadow: 0px 0px 20px 0px rgba(0, 102, 255, 0.2);}
.insane{    box-shadow: 0px 0px 20px 0px rgba(255, 0, 0, 0.2);}
.easybtn{   box-shadow: 0px 0px 5px 0px rgba(255, 255, 255, 0.4);}
.hardbtn{   box-shadow: 0px 0px 5px 0px rgba(0, 102, 255, 0.4);}
.insanebtn{ box-shadow: 0px 0px 5px 0px rgba(255, 0, 0, 0.4);}

.border{border: 1px solid white}
.grid {
  position: relative;
  margin: 5px;
  padding: 1px;
  width: 775px;
  min-width: 775px;
  height: 775px;
  min-height: 775px;
  margin: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  background-color: rgb(17, 17, 17);
  .img{
    position: absolute;
    left: 0;top: 0;
    width: 100%;
    opacity: .5;
  }
}
.border{border: 1px solid rgba(255, 255, 255, .8)}
.grid-item {
  outline: 1px solid rgb(20, 20, 20);
  width: 25px;
  height: 25px;
  z-index: 1000;
  background-color: rgb(17, 17, 17);
}
.modal{
  @include flex;
  flex-direction: column;
  margin: auto auto;
  width: 50%;
  padding: 20px;
  border-radius: 3px;
  border: 1px solid white;
  user-select: none;
  color: white;
  background-color: rgb(20, 20, 20);
  z-index: 1000;
  position: relative;
  h1{
    width:100%;
    padding-bottom: 20px;
    text-align: center;
  }
  button{
    margin: 0px 5px;
    padding: 10px 30px;
    border-radius: 3px;
    border: 1px solid rgba(255, 255, 255, 0.5);
    outline: none;
    cursor: pointer;
    color:white;
    background-color: transparent;
    transition: all .2s;
    &:hover{
      transition: all .3s;
      background-color: rgb(26, 26, 26);
    }
  }
}

.unavailable{
  background-color: rgb(17, 17, 17);
  position: absolute;
  left: 0; top: 0;
  width: 100vw;
  height: 100vh;
  @include flex;
  flex-direction: column;
  text-align: center;
  p{color:white; width: 100%;}
}

.help{
  position: absolute;
  text-align: center;
  top: 70px;
  margin: 0 auto;
  color: white;
}

.is-food, .is-head, .is-body, .is-tail{background-size: cover;}

.is-food{background-image: url('./gallery/apple.png')}
.is-head{background-image: url('./gallery/snake-head.png');}
.is-body{background-image: url('./gallery/snake-body.png');}
.is-tail{background-image: url('./gallery/snake-tail.png');}
.up {transform: rotate(0deg);}
.down {transform: rotate(180deg);}
.right {transform: rotate(90deg);}
.left {transform: rotate(270deg);}